import React, { useCallback, useState, useEffect } from 'react'
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader
} from 'reactstrap'

import api from '~/api'

export function AddCustomerModal(props) {
  const [name, setName] = useState('')
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    errors.name && setErrors({ ...errors, name: undefined })
  }, [name])

  function addCustomer() {
    return api
      .post('/customers/', { name })
      .then(() => {
        props.onSuccess()
        close()
      })
      .catch(({ response }) => {
        setErrors(response.data || {})
        setIsLoading(false)
      })
  }

  function submit() {
    event.preventDefault()

    setIsLoading(true)

    addCustomer()
  }

  function close() {
    setName('')
    setIsLoading(false)
    props.toggle()
  }

  return (
    <Modal isOpen={props.isOpen} toggle={close} fade={false}>
      <ModalHeader toggle={close}>Add Customer</ModalHeader>
      <ModalBody>
        <Form onSubmit={submit} noValidate>
          <FormGroup>
            <Label for='name'>Name</Label>
            <Input
              value={name}
              onChange={({ target }) => setName(target.value)}
              invalid={!!errors.name}
              name='name'
              id='name'
              placeholder='Name'
            />
            {errors.name && (
              <FormFeedback>{errors.name.join(' and ')}</FormFeedback>
            )}
          </FormGroup>
          <hr />
          <Button type='submit' disabled={isLoading} color='primary'>
            {isLoading ? 'Adding' : 'Add'}
          </Button>
          <Button color='link' onClick={close}>
            Cancel
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  )
}
