import React, { useState, useEffect } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Form,
  FormGroup,
  FormFeedback,
  Input
} from 'reactstrap'
import { useLocation } from 'react-router-dom'
import { useAuth } from './auth'

function ResetPassword() {
  const { resetPassword } = useAuth()

  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const search = useLocation().search
  const token = new URLSearchParams(search).get('token')

  useEffect(() => {
    errors.password &&
      setErrors({ ...errors, password: undefined, non_field: undefined })
  }, [password])

  function submit() {
    event.preventDefault()

    setIsLoading(true)

    resetPassword(token, password).catch(({ response }) => {
      setErrors(response.data || {})
      setIsLoading(false)
    })
  }

  const hasInvalidToken = !token || errors.token || errors.non_field
  const buttonText = (function () {
    if (isLoading) {
      return 'Submitting'
    } else if (hasInvalidToken) {
      return 'Invalid Token'
    } else {
      return 'Submit'
    }
  })()
  const hasErrors = hasInvalidToken || errors.password
  const isDisabled = !!(isLoading || hasErrors || !password)

  return (
    <Container className='reset-password'>
      <Card>
        <CardHeader className='text-center lead'>
          <strong>Reset Password</strong>
        </CardHeader>
        <CardBody>
          <Form onSubmit={submit} noValidate>
            <FormGroup>
              <Input
                type='password'
                value={password}
                onChange={({ target }) => setPassword(target.value)}
                invalid={!!errors.password}
                name='password'
                placeholder='Password'
              />
              {errors.password && (
                <FormFeedback>
                  <ul>
                    {errors.password.map((value, index) => (
                      <li key={index}>{value}</li>
                    ))}
                  </ul>
                </FormFeedback>
              )}
            </FormGroup>
            <Button block disabled={isDisabled} color='primary'>
              {buttonText}
            </Button>
          </Form>
        </CardBody>
      </Card>
    </Container>
  )
}

export default ResetPassword
