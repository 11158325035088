import React, { useState, useEffect } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  Form,
  FormGroup,
  FormFeedback,
  Input
} from 'reactstrap'
import { Link } from 'react-router-dom'

import { useAuth } from './auth'

function Login() {
  const { login } = useAuth()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setErrors({ ...errors, email: undefined, non_field: undefined })
  }, [email])

  useEffect(() => {
    setErrors({ ...errors, password: undefined, non_field: undefined })
  }, [password])

  function submit(event) {
    event.preventDefault()

    setIsLoading(true)

    login(email, password).catch(({ response }) => {
      setErrors(response.data || {})
      setIsLoading(false)
    })
  }

  const hasErrors = Object.values(errors).some((error) => !!error)
  const isDisabled = isLoading || hasErrors || !email || !password

  return (
    <Container className='login'>
      <Card>
        <CardHeader className='text-center lead'>
          <strong>Protex Admin</strong>
        </CardHeader>
        <CardBody>
          <Form onSubmit={submit} noValidate>
            <FormGroup>
              <Input
                type='email'
                value={email}
                onChange={({ target }) => setEmail(target.value)}
                invalid={!!errors.email || !!errors.non_field}
                name='email'
                placeholder='Email'
              />
              {errors.email && (
                <FormFeedback>{errors.email.join(' and ')}</FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <Input
                type='password'
                value={password}
                onChange={({ target }) => setPassword(target.value)}
                invalid={!!errors.password || !!errors.non_field}
                name='password'
                placeholder='Password'
              />
              {errors.password && (
                <FormFeedback>{errors.password.join(' and ')}</FormFeedback>
              )}
              {errors.non_field && (
                <FormFeedback>{errors.non_field}</FormFeedback>
              )}
            </FormGroup>
            <Button block disabled={isDisabled} color='primary'>
              {isLoading ? 'Logging In' : 'Log In'}
            </Button>
          </Form>
        </CardBody>
        <CardFooter>
          <Link to='/forgot-password'>Forgot Password?</Link>
        </CardFooter>
      </Card>
    </Container>
  )
}

export default Login
