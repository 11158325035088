import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Account from './accounts'
import Overview from './overview'
import Navigation from './navigation'

function Authenticated() {
  return (
    <>
      <Navigation />
      <Switch>
        <Route path='/account' component={Account} />
        <Route path='/' component={Overview} />
      </Switch>
    </>
  )
}

export default Authenticated
