import React, { useState } from 'react'
import { Container, Nav, Navbar, NavItem, NavLink } from 'reactstrap'
import { NavLink as RouterNavLink, Link } from 'react-router-dom'

import { useAuth } from '~/auth'
import Icon from '~/icons'

function Navigation() {
  const { auth, logout } = useAuth()

  return (
    <Nav className='bg-light root'>
      <Navbar color='light' className='me-auto'>
        <Link to='/' className='navbar-brand'>
          Protex Admin
        </Link>
      </Navbar>
      <Navbar color='light'>
        <NavItem>
          <Link to={`/account`} className='nav-link'>
            <Icon icon='user-circle' size='lg' />
            <span className='d-none d-md-inline-block'>{auth.user.email}</span>
          </Link>
        </NavItem>
        <NavItem>
          <NavLink onClick={logout} href='#'>
            <Icon icon='sign-out-alt' size='lg' />
            <span className='d-none d-md-inline-block'>Logout</span>
          </NavLink>
        </NavItem>
      </Navbar>
    </Nav>
  )
}

export default Navigation
