import React, { useState, useEffect } from 'react'
import {
  Container,
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  ListGroup,
  ListGroupItem
} from 'reactstrap'

import api from '~api'
import { AddCustomerModal } from './customers'

function Customers() {
  const [customers, setCustomers] = useState([])
  const [addCustomerModal, setAddCustomerModal] = useState(false)

  useEffect(refreshCustomers, [])

  function refreshCustomers() {
    return api
      .get(`/customers/`)
      .then((response) => response.data.results)
      .then(setCustomers)
  }

  function toggleAddCustomerModal() {
    setAddCustomerModal(!addCustomerModal)
  }

  return (
    <Card>
      <CardHeader className='d-flex my-auto justify-content-between'>
        Customers
        <>
          <Button onClick={toggleAddCustomerModal} color='primary' size='sm'>
            Add
          </Button>
          <AddCustomerModal
            isOpen={addCustomerModal}
            toggle={toggleAddCustomerModal}
            onSuccess={refreshCustomers}
          />
        </>
      </CardHeader>
      <ListGroup flush>
        {customers.map((customer) => (
          <ListGroupItem key={customer.id}>{customer.name}</ListGroupItem>
        ))}
      </ListGroup>
    </Card>
  )
}

function Overview() {
  return (
    <Container fluid>
      <Row md='2'>
        <Col>
          <Customers />
        </Col>
      </Row>
    </Container>
  )
}

export default Overview
