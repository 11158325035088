import React, { useState, useEffect } from 'react'
import {
  Button,
  Container,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Row,
  Col
} from 'reactstrap'

import { useAuth } from '~/auth'

function UpdateAccount() {
  const { auth, updateAccount } = useAuth()

  const [email, setEmail] = useState(auth.user.email)
  const [firstName, setFirstName] = useState(auth.user.first_name)
  const [lastName, setLastName] = useState(auth.user.last_name)
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    errors.email && setErrors({ ...errors, email: undefined })
  }, [email])

  useEffect(() => {
    errors.first_name && setErrors({ ...errors, first_name: undefined })
  }, [firstName])

  useEffect(() => {
    errors.last_name && setErrors({ ...errors, last_name: undefined })
  }, [lastName])

  useEffect(() => {
    setEmail(auth.user.email)
    setFirstName(auth.user.first_name)
    setLastName(auth.user.last_name)
  }, [auth])

  const hasErrors = Object.values(errors).some((error) => !!error)
  const isDisabled = isLoading || hasErrors || !email || !firstName || !lastName

  function submit(event) {
    event.preventDefault()

    setIsLoading(true)

    updateAccount({ email, first_name: firstName, last_name: lastName })
      .catch(({ response }) => {
        setErrors(response.data || {})
      })
      .then(() => setIsLoading(false))
  }

  return (
    <Card>
      <CardHeader>Update Account</CardHeader>
      <CardBody>
        <Form onSubmit={submit} noValidate>
          <FormGroup>
            <Label for='email'>Email</Label>
            <Input
              type='email'
              value={email}
              onChange={({ target }) => setEmail(target.value)}
              invalid={!!errors.email}
              name='email'
              id='email'
              placeholder='Email'
            />
            {errors.email && (
              <FormFeedback>{errors.email.join(' and ')}</FormFeedback>
            )}
          </FormGroup>
          <FormGroup>
            <Label for='firstName'>First Name</Label>
            <Input
              value={firstName}
              onChange={({ target }) => setFirstName(target.value)}
              invalid={!!errors.first_name}
              name='firstName'
              id='firstName'
              placeholder='First Name'
            />
            {errors.first_name && (
              <FormFeedback>{errors.first_name.join(' and ')}</FormFeedback>
            )}
          </FormGroup>
          <FormGroup>
            <Label for='lastName'>Last Name</Label>
            <Input
              value={lastName}
              onChange={({ target }) => setLastName(target.value)}
              invalid={!!errors.last_name}
              name='lastName'
              id='lastName'
              placeholder='Last Name'
            />
            {errors.last_name && (
              <FormFeedback>{errors.last_name.join(' and ')}</FormFeedback>
            )}
          </FormGroup>
          <Button block disabled={isDisabled} color='primary'>
            {isLoading ? 'Updating' : 'Update'}
          </Button>
        </Form>
      </CardBody>
    </Card>
  )
}

function ChangePassword() {
  const { auth, changePassword } = useAuth()

  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    errors.password && setErrors({ ...errors, password: undefined })
  }, [password])

  function clear() {
    setPassword('')
  }

  function submit(event) {
    event.preventDefault()

    setIsLoading(true)

    changePassword(password)
      .then(clear)
      .catch(({ response }) => {
        setErrors(response.data || {})
      })
      .then(() => setIsLoading(false))
  }

  const hasErrors = Object.values(errors).some((error) => !!error)
  const isDisabled = isLoading || hasErrors || !password

  return (
    <Card>
      <CardHeader>Change Password</CardHeader>
      <CardBody>
        <Form onSubmit={submit} noValidate>
          <FormGroup>
            <Label for='password'>Password</Label>
            <Input
              type='password'
              value={password}
              onChange={({ target }) => setPassword(target.value)}
              invalid={!!errors.password}
              name='password'
              id='password'
              placeholder='Password'
            />
            {errors.password && (
              <FormFeedback>
                <ul>
                  {errors.password.map((value, index) => (
                    <li key={index}>{value}</li>
                  ))}
                </ul>
              </FormFeedback>
            )}
          </FormGroup>
          <Button block disabled={isDisabled} color='primary'>
            {isLoading ? 'Changing Password' : 'Change Password'}
          </Button>
        </Form>
      </CardBody>
    </Card>
  )
}

function Account() {
  return (
    <Container>
      <Row>
        <Col md='6'>
          <UpdateAccount />
        </Col>
        <Col md='6'>
          <ChangePassword />
        </Col>
      </Row>
    </Container>
  )
}

export default Account
